<template>
  <v-btn
    small
    v-if="create"
    v-bind="buttonProperties"
    color="red lighten-1"
    class="white--text"
    @click="deleteAction"
  >
    <v-icon v-if="icon" left>{{icon}}</v-icon>
    <span>{{ deleteLabel|t }}</span>
  </v-btn>
</template>

<script lang="babel" type="text/babel">
import listActionMixins from '@/components/list/listDataAction/listDataAction.js'
export default {
  mixins: [listActionMixins],
  methods: {
    deleteAction() {
      this.$apopup.base({
        title: this.confirmTitle,
        content: this.removeConfirmContent || this.removeTarget,
        applyCallback: async () => {
          if(this.config.confirmIdentity != true) {
            await this.removeRequest()
            return
          }

          await this.$helper.account.verify(this.removeRequest)
        },
      })
    },
    async removeRequest() {
      const target = this.row[this.listTargetKey]
      try {
        await this.listApi.deleteApi(target)
        this.$snotify.success(null, this.$t('delete.successfully') )
      } catch (error) {
        console.error(error)
        this.$snotify.error(
          this.$t('error.unexpect'),
          this.$t('delete.failure'),
        )
      } finally {
        this.listActions.index()
      }
    },
  },
  computed: {
    confirmTitle() {
      if(typeof this.config.confirmTitle != 'function') return this.$t(`delete.confirm`)
      return this.$t(this.config.confirmTitle(this.row))
    },
    removeTarget() {
      if(typeof this.config.removeTarget != 'function') return null
      return this.config.removeTarget(this.row)
    },
    removeConfirmContent() {
      if(typeof this.config.removeConfirmContent != 'function') return null
      return this.$t(this.config.removeConfirmContent(this.row))
    },
    deleteLabel() {
      return this.label || 'action.delete'
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
